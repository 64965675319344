<template>
  <v-card>
    <v-row>
      <template v-for="i in 6">
        <v-col v-if="calendarShown[i]" :key="'cal-' + i" :cols="i === 1 ? 1 : ''" :class="'pr-0' + (i > 1 ? ' pl-0' : '')">
          <v-calendar :ref="calendar[i]" v-model="calendarDay[i]" :now="today" :events="filteredEvents" :class="i > 1 ? 'hideTimes' : 'onlyTimes'" mode="stack" color="primary" type="day" first-interval="30" interval-minutes="20" interval-count="15" @mousedown:time="clickTime" @click:event="clickEvent"></v-calendar>
        </v-col>
      </template>
      <v-col cols="1">
        <div style="overflow:hidden;text-overflow:ellipsis;font-size:.8em;white-space:nowrap;max-width:100%">Instructors</div>
        <v-menu :close-on-content-click="false" :disabled="editTimesMode" nudge-left="10" left offset-x>
          <template v-slot:activator="{ on: menuOn }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltipOn }">
                <v-btn v-on="{ ...menuOn, ...tooltipOn }" fab style="margin:0 1em 1em 0">
                  <v-icon>{{ selectedInstructors.length > 0 ? 'fas' : 'far' }} fa-filter</v-icon>
                </v-btn>
              </template>
              <span>Filter by Instructor(s)</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-subheader>Instructor Filter</v-subheader>
            <v-list>
              <v-list-item-group v-model="selectedInstructors" color="primary" multiple>
                <v-list-item v-for="name in instructors" :key="'instr-' + name" :value="name">
                  <v-list-item-title>{{ name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <v-tooltip :disabled="editTimesMode" top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab style="margin-bottom:1em" @click="openAddNameDialog('instr')">
              <v-icon>far fa-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Instructor</span>
        </v-tooltip>
        <v-divider style="margin-bottom:1em;margin-left:-8px"></v-divider>
        <div style="overflow:hidden;text-overflow:ellipsis;font-size:.8em;max-width:100%;white-space:nowrap;">Accompanists</div>
        <v-menu :close-on-content-click="false" :disabled="editTimesMode" nudge-left="10" left offset-x>
          <template v-slot:activator="{ on: menuOn }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltipOn }">
                <v-btn v-on="{ ...menuOn, ...tooltipOn }" fab style="margin:0 1em 1em 0">
                  <v-icon>{{ selectedAccompanists.length > 0 ? 'fas' : 'far' }} fa-filter</v-icon>
                </v-btn>
              </template>
              <span>Filter by Accompanist(s)</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-subheader>Accompanist Filter</v-subheader>
            <v-list>
              <v-list-item-group v-model="selectedAccompanists" color="primary" multiple>
                <v-list-item v-for="name in accompanists" :key="'accomp-' + name" :value="name">
                  <v-list-item-title>{{ name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <v-tooltip :disabled="editTimesMode" top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab style="margin-bottom:1em" @click="openAddNameDialog('accomp')">
              <v-icon>far fa-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Accompanist</span>
        </v-tooltip>
        <v-divider style="margin-bottom:1em;margin-left:-8px"></v-divider>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn :disabled="selectedInstructors.length + selectedAccompanists.length !== 1 || editTimesMode" color="info" fab style="margin:0 1em 1em 0" @click="editTimesMode = true">
                <v-icon>far fa-pencil</v-icon>
              </v-btn>
            </span>
          </template>
          <span v-if="editTimesMode">While editing times you must choose to Cancel or Save to complete the editing</span>
          <span v-else-if="selectedInstructors.length + selectedAccompanists.length === 1">Edit time availability for {{ selectedInstructors[0] || selectedAccompanists[0] }}</span>
          <span v-else>Editing is only available when a single instructor or accompanist is selected</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn :disabled="!editTimesMode" color="error" fab style="margin-bottom:1em" @click="cancelTimeEdit()">
                <v-icon>far fa-times</v-icon>
              </v-btn>
            </span>
          </template>
          <span v-if="editTimesMode">Cancel Changes</span>
          <span v-else>Not in editing mode; select an instructor or accompanist and click the edit button above</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn :disabled="!editTimesMode" color="success" fab @click="finishTimeEdit()">
                <v-icon>far fa-save</v-icon>
              </v-btn>
            </span>
          </template>
          <span v-if="editTimesMode">Save Changes</span>
          <span v-else>Not in editing mode; select an instructor or accompanist and click the edit button above</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-dialog v-model="addNameDialog" width="300">
      <v-card>
        <v-card-title>Add {{ nameTypeLabel }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="newName" label="Name" outlined hide-details></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="newName === ''" text color="success" @click="addPerson">Add</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="addNameDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updatingTimes" width="300" persistent>
      <v-card>
        <v-card-title>Updating Available Times</v-card-title>
        <v-card-text>
          Updated {{ updatedTimes }} out of {{ updateTotal }} Slots
          <v-progress-linear :value="(updatedTimes / updateTotal) * 100"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="termSetupDialog" width="400">
      <v-card v-if="runningSetup">
        <v-card-title>Set Up New Term</v-card-title>
        <v-card-text>
          <p>Setting up slots now. This should only take a few seconds...</p>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>Set Up New Term</v-card-title>
        <v-card-text>
          <p>Please select the dates to use for the juries.</p>
          <v-list>
            <v-list-item v-for="(date, index) in termSetupDates" :key="date">
              <v-list-item-title>{{ stringFormatDate(date, true) }}</v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="termSetupDates.splice(index, 1)">
                  <v-icon color="error">fal fa-trash-alt</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">Add Date</v-btn>
            </template>
            <v-date-picker v-model="newDate" no-title scrollable></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" @click="addTerm">Set Up Term</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="termSetupDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addStudentDialog" width="400">
      <v-card>
        <v-card-title>Add Student to Time Slot</v-card-title>
        <v-card-text>
          <p>Search for and select a student using the box below to add them to the {{ selectedEventStart }} time slot. If this is a sophomore platform, then check that box which will take 2 slots (this option is only available if the slot after the selected slot is also open).</p>
          <directory-search v-model="addDetail.student" :person-filter="['Student']" search-label="Student"></directory-search>
          <v-select v-model="addDetail.instructor" :items="addStudentInstructorOptions" label="Instructor" outlined></v-select>
          <v-select v-model="addDetail.accompanist" :items="addStudentAccompanistOptions" label="Accompanist (leave blank for none)" outlined></v-select>
          <v-select v-model="addDetail.instrument" :items="instruments" label="Instrument" outlined></v-select>
          <v-checkbox v-if="addStudentSophomoreSlot" v-model="addStudentSophomore" label="Sophomore Platform (20 minutes)" class="pt-0 mt-0"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="addStudentDialog = false">Cancel</v-btn>
          <v-btn :disabled="addDetail.student === '' || addDetail.instructor === '' || addDetail.instrument === ''" color="success" text @click="addStudent()">Add Student</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<style>
.v-calendar-daily__scroll-area {
  overflow-y: hidden;
}
div.v-calendar.hideTimes div.v-calendar-daily__intervals-head, div.v-calendar.hideTimes div.v-calendar-daily__intervals-body {
  display: none;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-head {
  height: 79.5px;
  width: 100% !important;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-head::after {
  content: '10:00 AM';
  font-size: 10px;
  padding-right: 12px;
  text-align: right;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-body {
  width: 100% !important;
}
div.v-calendar.onlyTimes div.v-calendar-daily_head-day, div.v-calendar.onlyTimes div.v-calendar-daily__day {
  display: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}
.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: '';
}

.v-event-drag-bottom:hover::after {
  display: block;
}
</style>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  props: {
    term: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const view = ref('All')
    const termEnd = ref('2022-05-05')
    const events = ref([])
    const personPanel = ref(0)
    const showFilters = ref(false)
    const selectedInstructors = ref([])
    const selectedAccompanists = ref([])
    const filteredEvents = computed(() => {
      if (selectedInstructors.value.length === 0 && selectedAccompanists.value.length === 0) {
        return events.value
      }
      let arr = []
      for (let i = 0; i < events.value.length; i++) {
        if (events.value[i].name === 'Pending' || events.value[i].name.substring(0, 8) === 'Add for ') {
          arr.push(events.value[i])
          continue
        }
        let hasInstr = false
        if (selectedInstructors.value.length > 0) {
          for (const instr of events.value[i].instr) {
            for (const name of selectedInstructors.value) {
              if (instr === name) {
                hasInstr = true
                break
              }
            }
            if (hasInstr) break
          }
          if (!hasInstr) continue
        }
        let hasAccomp = false
        if (selectedAccompanists.value.length > 0) {
          for (const accomp of events.value[i].accomp) {
            for (const name of selectedAccompanists.value) {
              if (accomp === name) {
                hasAccomp = true
                break
              }
            }
            if (hasAccomp) break
          }
          if (!hasAccomp) continue
        }
        arr.push(events.value[i])
      }
      return arr
    })

    const service = root.$feathers.service('student/music/jury-slot')
    watch(() => props.term, () => {
      loadSlots()
    })
    const termSetupDialog = ref(false)
    const termSetupDates = ref([])
    const newDate = ref('')
    const runningSetup = ref(false)
    watch(newDate, () => {
      if (newDate.value !== '') {
        const dt = new Date(newDate.value)
        dt.setHours(dt.getHours() + 12)
        const newStr = dt.toLocaleDateString()
        if (termSetupDates.value.filter((dt) => dt === newStr).length === 0) {
          termSetupDates.value.push(newStr)
          termSetupDates.value.sort((a, b) => new Date(a) < new Date(b) ? -1 : 1)
        }
      }
    })
    async function loadSlots () {
      events.value = []
      const slots = []
      if (props.term === '') return
      const query = { term: props.term, $sort: { start: 1 }, $limit: 0, active: true }
      const { total } = await service.find({ query })
      if (total === 0) {
        if (confirm('The selected term (' + props.term + ') has no slots set up. Would you like to set up the default slots now?')) {
          const { data } = await root.$feathers.service('system/term').find({ query: { term: props.term } })
          if (data.length === 0) {
            alert('The term was not found in the system table. Please contact Technology Services for assistance.')
            return
          }
          // Find the reading days in the closures
          const { closures } = data[0]
          let found = false
          for (const { label, start } of closures) {
            if (label.match(/reading/ig) != null) {
              let dt = new Date(start)
              dt.setHours(-10)
              dt.setHours(10)
              termSetupDates.value.push(dt.toLocaleDateString())
              for (let i = 0; i < 6; i++) {
                // Skip Saturday and Sunday
                dt.setDate(dt.getDate() + 1)
                if (dt.getDay() === 0 || dt.getDay() === 6) {
                  continue
                }
                termSetupDates.value.push(dt.toLocaleDateString())
              }
              found = true
            }
          }
          if (!found) {
            alert('No reading day was found, which means the campus closures were not set up by the Records office or were given a different label. Please contact Technology Services for assistance.')
          } else {
            termSetupDialog.value = true
          }
        }
      } else {
        let dates = {}
        query.$limit = 50
        for (let i = 0; i < total; i += 50) {
          query.$skip = i
          query.$populate = 'student'
          const { data } = await service.find({ query })
          for (const row of data) {
            let dt = new Date(row.start)
            let [month, day, year] = dt.toLocaleDateString().split('/')
            if (parseInt(month) < 10) month = '0' + month
            if (parseInt(day) < 10) day = '0' + day
            let date = year + '-' + month + '-' + day
            if (!(date in dates)) dates[date] = true
            slots.push(formatEvent(row))
            addAvail(row.avail)
          }
        }
        let index = 2
        for (const date in dates) {
          calendarDay.value[index] = date
          if (index === 2) calendarDay.value[1] = date
          index++
        }
        if (index <= 6) {
          while (index <= 6) {
            calendarShown.value.splice(index, 1, false)
            index++
          }
        }
        events.value = slots
      }
    }
    async function addTerm () {
      runningSetup.value = true
      for (const dt of termSetupDates.value) {
        let start = new Date(dt)
        start.setHours(10)
        while (start.getHours() < 15) {
          let end = new Date(start)
          end.setMinutes(end.getMinutes() + 10)
          if (start.getHours() !== 12) {
            await service.create({ term: props.term, start, end })
          }
          start.setMinutes(start.getMinutes() + 10)
        }
      }
      runningSetup.value = false
      termSetupDialog.value = false
      loadSlots()
    }
    function formatEvent (data) {
      const { _id: id, start, end, student, avail } = data
      let name = 'Open'
      let color = 'grey'
      if (student) {
        name = student.name.first + ' ' + student.name.last
        color = 'success'
      }
      const obj = {
        id,
        name,
        start: new Date(start),
        end: new Date(end),
        color,
        timed: true,
        instr: avail.instr || [],
        accomp: avail.accomp || []
      }
      return obj
    }
    onMounted(async () => {
      root.$store.commit('setSideNavActive', false)
    })

    const calendar = ref([])
    const calendarDay = ref(['', '', '', '', '', '', ''])
    const calendarShown = ref([true, true, true, true, true, true, true])
    const dt = new Date()
    let [month, day, year] = dt.toLocaleDateString().split('/')
    if (month < '10') month = '0' + month
    if (day < '10') day = '0' + day
    const today = ref(year + '-' + month + '-' + day)

    const isPending = ref(false)

    function clickTime ({ date, time }) {
      if (selectedAccompanists.value.length + selectedInstructors.value.length === 1 && editTimesMode.value) {
        const dt = new Date(date + ' ' + time)
        if (isPending.value) {
          dt.setMinutes(Math.ceil(dt.getMinutes() / 10) * 10)
          const index = events.value.length - 1
          const { start } = events.value[index]
          const name = selectedInstructors.value.length > 0 ? selectedInstructors.value[0] : selectedAccompanists.value[0]
          events.value.splice(index, 1, { name: 'Add for ' + name, start, end: dt, color: 'blue', timed: true })
          isPending.value = false
        } else {
          // See if they are clicking on a time value that already exists; if so, don't do anything
          for (let i = 0; i < filteredEvents.value.length; i++) {
            if (filteredEvents.value[i].start <= dt && filteredEvents.value[i].end >= dt) return
          }
          dt.setMinutes(Math.floor(dt.getMinutes() / 10) * 10)
          const end = new Date(dt)
          end.setMinutes(end.getMinutes() + 10)
          events.value.push({ name: 'Pending', start: dt, end, color: 'orange', timed: true })
          isPending.value = true
        }
      }
    }
    async function clickEvent ({ nativeEvent, event }) {
      nativeEvent.stopPropagation()
      if (editTimesMode.value) {
        if (event.name.substring(0, 12) === 'Remove from ') {
          const data = await service.get(event.id)
          if ('student' in data && data.student != null) {
            const stu = await root.$feathers.service('directory/people').get(data.student)
            data.name = stu.name
            data.color = 'success'
          } else {
            data.name = 'Open'
            data.color = 'grey'
          }
          for (let i = 0; i < events.value.length; i++) {
            if (events.value[i].id === event.id) {
              events.value.splice(i, 1, formatEvent(data))
              break
            }
          }
        } else if (event.name.substring(0, 8) === 'Add for ') {
          for (let i = 0; i < events.value.length; i++) {
            if (events.value[i].name === event.name && events.value[i].start === event.start && events.value[i].end === event.end) {
              events.value.splice(i, 1)
            }
          }
        } else if (event.name.substring(0, 4) === 'Open') {
          // Remove the seleted person from the event
          for (let i = 0; i < events.value.length; i++) {
            if (events.value[i].id === event.id) {
              const name = selectedInstructors.value.length > 0 ? selectedInstructors.value[0] : selectedAccompanists.value[0]
              const obj = { ...event, name: 'Remove from ' + name, color: 'red' }
              events.value.splice(i, 1, obj)
              break
            }
          }
        }
      } else if (event.name.substring(0, 4) === 'Open') {
        // Open a dialog where they can add a student to the selected slot
        // Will require the selection of the instructor and accompanist (limited to ones available at the selected time), and the instrument
        addDetail.value = {
          instructor: '',
          accompanist: '',
          instrument: '',
          student: {}
        }
        addStudentInstructorOptions.value = event.instr
        addStudentAccompanistOptions.value = event.accomp
        selectedEventId.value = event.id
        const dt = new Date(event.start)
        selectedEventStart.value = dt.toLocaleString()
        // Check to see if the slot after the selected one is open; if so, enable the sophomore platform checkbox
        dt.setMinutes(dt.getMinutes() + 10)
        const temp = events.value.filter(({ start }) => new Date(start).toISOString() === dt.toISOString())
        addStudentSophomoreSlot.value = (temp.length > 0 && temp[0].name.substring(0, 4) === 'Open' ? temp[0].id : false)
        addStudentDialog.value = true
      } else {
        root.$router.push('/student/music/jury/' + event.id)
      }
    }

    const nameMenu = ref(false)
    const instructors = ref([])
    const accompanists = ref([])
    function addAvail ({ instr, accomp }) {
      for (const name of instr) {
        let exists = false
        for (let i = 0; i < instructors.value.length; i++) {
          if (instructors.value[i] === name) {
            exists = true
            break
          }
        }
        if (!exists) instructors.value.push(name)
      }
      for (const name of accomp) {
        let exists = false
        for (let i = 0; i < accompanists.value.length; i++) {
          if (accompanists.value[i] === name) {
            exists = true
            break
          }
        }
        if (!exists) accompanists.value.push(name)
      }
    }

    const addNameDialog = ref(false)
    const nameType = ref('')
    const nameTypeLabel = computed(() => {
      return (nameType.value === 'instr' ? 'Instructor' : 'Accompanist')
    })
    function openAddNameDialog (type) {
      nameType.value = type
      addNameDialog.value = true
    }
    const newName = ref('')
    async function addPerson () {
      // Check to make sure this is a new name; if not, then reload the list and switch to that name
      const { total } = await service.find({ query: { term: props.term, ['avail.' + nameType.value]: newName.value, $limit: 0 } })
      if (total > 0) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'warning', text: 'Name is already in the database; reloading list', timeout: 6000 })
        await loadSlots()
        return
      }
      if (nameType.value === 'instr') {
        instructors.value.push(newName.value)
        selectedInstructors.value = [newName.value]
        selectedAccompanists.value = []
      } else {
        accompanists.value.push(newName.value)
        selectedInstructors.value = []
        selectedAccompanists.value = [newName.value]
      }
      addNameDialog.value = false
    }

    const editTimesMode = ref(false)
    function cancelTimeEdit () {
      loadSlots()
      editTimesMode.value = false
    }
    async function finishTimeEdit () {
      updatingTimes.value = true
      updateTotal.value = 0
      updatedTimes.value = 0
      for (let i = 0; i < events.value.length; i++) {
        const field = (selectedInstructors.value.length > 0 ? 'instr' : 'accomp')
        const person = (selectedInstructors.value.length > 0 ? selectedInstructors.value[0] : selectedAccompanists.value[0])
        const { id, name, start, end } = events.value[i]
        if (name.substring(0, 8) === 'Add for ') {
          // Find all of the time slots between the start and end dates that do not already have this instructor/accompanist and all them
          const query = { term: props.term, start: { $gte: start }, end: { $lte: end }, $limit: 0 }
          query.$or = [{ ['avail.' + field + '.0']: { $exists: false } }, { ['avail.' + field]: { $ne: person } }]
          const $push = { ['avail.' + field]: person }
          const { total } = await service.find({ query })
          updateTotal.value += total
          query.$limit = 20
          for (let i = 0; i < total; i += 20) {
            const { data } = await service.find({ query })
            for (const { _id } of data) {
              await service.patch(_id, { $push })
              updatedTimes.value++
            }
          }
        } else if (name.substring(0, 12) === 'Remove from ') {
          const data = await service.get(id)
          for (let i = 0; i < data.avail[field].length; i++) {
            if (data.avail[field][i] === person) {
              data.avail[field].splice(i, 1)
              break
            }
          }
          await service.patch(id, { ['avail.' + field]: data.avail[field] })
        }
      }
      editTimesMode.value = false
      updatingTimes.value = false
      loadSlots()
    }

    const updatingTimes = ref(false)
    const updatedTimes = ref(0)
    const updateTotal = ref(0)

    const instruments = ref(['Piano', 'Organ', 'Voice', 'Violin', 'Viola', 'Cello', 'String Bass', 'Guitar', 'Jazz Piano', 'Trumpet', 'French Horn', 'Trombone', 'Euphonium', 'Tuba', 'Flute', 'Clarinet', 'Oboe', 'English Horn', 'Bass Clarinet', 'Bassoon', 'Percussion'])
    const addStudentInstructorOptions = ref([])
    const addStudentAccompanistOptions = ref([])
    const addStudentDialog = ref(false)
    const addStudentSophomoreSlot = ref(false)
    const addStudentSophomore = ref(false)
    const addDetail = ref({
      instructor: '',
      accompanist: '',
      instrument: '',
      student: {}
    })
    const selectedEventId = ref('')
    const selectedEventStart = ref('')
    async function addStudent () {
      const patch = { ...addDetail.value }
      patch.student = addDetail.value.student.value
      if (addStudentSophomoreSlot.value && addStudentSophomore.value) {
        // Update the second slot to be inactive, and add to the patch to change the end time to be 20 minutes long instead of 10
        const { end } = await service.get(addStudentSophomoreSlot.value)
        await service.patch(addStudentSophomoreSlot.value, { active: false })
        patch.end = end
      }
      await service.patch(selectedEventId.value, patch)
      addStudentDialog.value = false
      loadSlots()
    }

    return {
      user,
      roles,
      view,
      termEnd,
      events,
      showFilters,
      selectedInstructors,
      selectedAccompanists,
      filteredEvents,
      calendar,
      calendarDay,
      calendarShown,
      today,
      isPending,
      termSetupDialog,
      termSetupDates,
      newDate,
      runningSetup,
      addTerm,
      formatEvent,
      clickTime,
      clickEvent,
      nameMenu,
      instructors,
      accompanists,
      personPanel,
      addNameDialog,
      nameType,
      nameTypeLabel,
      openAddNameDialog,
      newName,
      addPerson,
      editTimesMode,
      cancelTimeEdit,
      finishTimeEdit,
      updatingTimes,
      updatedTimes,
      updateTotal,
      instruments,
      addStudentInstructorOptions,
      addStudentAccompanistOptions,
      addStudentDialog,
      addStudentSophomoreSlot,
      addStudentSophomore,
      addDetail,
      selectedEventId,
      selectedEventStart,
      addStudent,
      stringFormatDate
    }
  }
}
</script>
